import {Route} from 'react-router-dom';
import Server404 from 'components/Errors/Server404';
import MenuSidebarLayout from 'components/Layout/MenuSideBarLayout';
import PageLayout from 'components/Layout/PageLayout';
import Bookmarks from 'components/User/Account/Bookmarks';
import UserPaymentMethods from 'components/User/Account/PaymentMethods/PaymentMethods';
import {Profile} from 'components/User/Account/Profile/Profile';
import {CancelReservation} from 'components/User/Account/Reservations/CancelReservation/CancelReservation';
import {Message} from 'components/User/Account/Reservations/Message/Message';
import {ReservationList} from 'components/User/Account/Reservations/ReservationList';
import {Settings} from 'components/User/Account/Settings/Settings';
import userAccountSections from 'components/User/Account/user-account-sections';
import {Analytics, PageName} from 'pages/Analytics';
import {CanonicalUrl} from 'pages/CanonicalUrl';

const AccountRoutes = () => (
    <Route element={<PageLayout isAuthenticatedPage={true} />}>
        <Route path="account/*">
            <Route element={<Server404 />} path="*" />
            <Route
                element={
                    <MenuSidebarLayout
                        sections={userAccountSections}
                        showHeader={true}
                    />
                }
            >
                <Route
                    element={
                        <CanonicalUrl>
                            <Analytics pageName={PageName.Bookmarks}>
                                <Bookmarks />
                            </Analytics>
                        </CanonicalUrl>
                    }
                    path="bookmarks"
                />
                <Route
                    element={
                        <CanonicalUrl>
                            <Analytics pageName={PageName.Reservations}>
                                <ReservationList />
                            </Analytics>
                        </CanonicalUrl>
                    }
                    path="reservations"
                />
                <Route path="reservations/:reservationId">
                    <Route
                        element={
                            <CanonicalUrl blockRobots={true}>
                                <Analytics pageName={PageName.Cancel}>
                                    <CancelReservation />
                                </Analytics>
                            </CanonicalUrl>
                        }
                        path="cancel"
                    />
                    <Route
                        element={
                            <CanonicalUrl blockRobots={true}>
                                <Analytics pageName={PageName.Message}>
                                    <Message />
                                </Analytics>
                            </CanonicalUrl>
                        }
                        path="message"
                    />
                </Route>
                <Route
                    element={
                        <CanonicalUrl>
                            <Analytics pageName={PageName.Profile}>
                                <Profile />
                            </Analytics>
                        </CanonicalUrl>
                    }
                    index={true}
                    path="profile"
                />
                <Route
                    element={
                        <CanonicalUrl>
                            <Analytics pageName={PageName.PaymentMethods}>
                                <UserPaymentMethods />
                            </Analytics>
                        </CanonicalUrl>
                    }
                    path="payment-methods"
                />
                <Route
                    element={
                        <CanonicalUrl>
                            <Analytics pageName={PageName.Settings}>
                                <Settings />
                            </Analytics>
                        </CanonicalUrl>
                    }
                    path="settings"
                />
            </Route>
        </Route>
    </Route>
);

export default AccountRoutes;

import {FC, ReactNode, useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import Button from 'components/Button';
import Form from 'components/Form';
import InputEmail from 'components/Form/InputEmail';
import InputPassword from 'components/Form/InputPassword';
import SubmitButton from 'components/Form/SubmitButton';
import Link from 'components/Link';
import {LoginDocument} from 'gql/generated';
import {useCredentialsDispatch} from 'state/credentials';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

type LoginFormData = FormData<{
    email: string;
    password: string;
}>;

export type LoginProps = {
    children?: ReactNode;
    message?: string;
    onSignup: () => void;
    onSuccess?: () => void;
};

const Login: FC<LoginProps> = ({children, message, onSignup, onSuccess}) => {
    const {t} = useTranslation();
    const setCredentials = useCredentialsDispatch();
    const {toastWrapper} = useToastNotification();

    const methods = useForm<LoginFormData>();

    const {handleSubmit} = methods;

    const [login, {data}] = useMutation(LoginDocument);

    useEffect(() => {
        if (data) {
            setCredentials(data.userLogin.credentials);
            onSuccess?.();
        }
    }, [data, onSuccess, setCredentials]);

    const onSubmit = async (formData: LoginFormData) => {
        await toastWrapper({
            promise: login({variables: {...formData}}),
        });
    };

    return (
        <div className="bg-body border-outline relative px-5 pb-5 pt-4 sm:min-w-[36rem] sm:max-w-xl sm:rounded-md sm:border sm:shadow-lg">
            {children ? (
                <div className="border-outline flex items-center justify-between gap-4 border-b px-0.5 pb-4">
                    <h2 className="text-lg">
                        {t('authentication.login.title')}
                    </h2>
                    {children}
                </div>
            ) : (
                <h2 className="border-outline border-b pb-2 text-lg">
                    {t('authentication.login.title')}
                </h2>
            )}
            <FormProvider {...methods}>
                <Form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
                    {message && <div>{message}</div>}
                    <InputEmail name="email" required={true} />
                    <InputPassword
                        autoComplete="current-password"
                        name="password"
                        rules={{required: true}}
                    />
                    <SubmitButton className="mx-auto w-11/12 sm:w-1/2">
                        {t('authentication.login.title')}
                    </SubmitButton>
                </Form>
            </FormProvider>
            <div className="mt-6 text-sm">
                <div>
                    <Link className="underline" to="/forgot-password">
                        {t('authentication.login.linkToForgotPassword')}
                    </Link>
                </div>
                <div className="mt-2">
                    <Link className="underline" to="/resend-confirmation">
                        {t('authentication.login.linkToResendConfirmation')}
                    </Link>
                </div>
            </div>
            <div className="mt-6 text-center">
                <Button
                    className="mx-auto w-11/12 sm:w-1/2"
                    kind="tertiary"
                    onClick={onSignup}
                >
                    {t('authentication.login.linkToSignup')}
                </Button>
            </div>
        </div>
    );
};

export default Login;

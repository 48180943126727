import packageInfo from '../../../package.json';

export const SEMVER = packageInfo.version;

export const SEMVER_COMMIT_HASH = `${packageInfo.version}-${
    process.env.COMMIT_HASH || 'XXXXXXX'
}`;

export const wwwDomainUrl = 'https://www.pocket-concierge.jp';

// The regex removes a trailing slash if present
export const getCleanUrl = () =>
    `${wwwDomainUrl}${window.location.pathname.replace(/\/$/, '')}`;

export const isProduction = () =>
    typeof window !== 'undefined' &&
    ['https://www.pocket-concierge.jp', 'https://pocket-concierge.jp'].includes(
        window.location.origin
    );

export const getEnvironmentName = () =>
    isProduction() ? 'production' : 'staging';

export const getS3ImageURL = (imageName: string, fileEnding = 'jpg') =>
    `${process.env.MARKETING_IMAGES_URL}/${imageName}.${fileEnding}`;

import {FC, useEffect, useState} from 'react';
import {createPortal} from 'react-dom';
import {useTranslation} from 'react-i18next';
import {Link as ReactRouterLink} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import DarkModeToggle from 'components/DarkModeToggle';
import Link from 'components/Link';
import LocaleSelector from 'components/LocaleSelector';
import useBreakpointChange from 'hooks/useBreakpointChange';
import {useLogout} from 'hooks/useLogout';
import {useLocale} from 'state/locale';
import {AuthenticationMode} from 'types';
import styles from './styles.module.css';

export type MobileUserMenuProps = {
    fullName?: string;
    setAuth: (value: AuthenticationMode) => void;
};

const MobileUserMenu: FC<MobileUserMenuProps> = ({fullName, setAuth}) => {
    const {t} = useTranslation();
    const locale = useLocale();

    const [isOpen, setIsOpen] = useState(false);

    const logout = useLogout(() => setIsOpen(false));

    useBreakpointChange(() => setIsOpen(false));

    const onClick = () => setIsOpen((p) => !p);

    useEffect(() => {
        if (isOpen) {
            document.documentElement.classList.add(
                'portal-mobile-menu',
                'portal-mobile-menu-in'
            );
        } else {
            document.documentElement.classList.remove('portal-mobile-menu');
        }
        const cover = document.querySelector('#cover');
        cover?.addEventListener('click', onClick);

        return () => {
            document.documentElement.classList.remove(
                'portal-mobile-menu',
                'portal-mobile-menu-in'
            );
            cover?.removeEventListener('click', onClick);
        };
    }, [isOpen]);

    const Button = (
        <div className="flex items-center pt-1">
            <button
                className={clsx(styles.button, isOpen && styles.open)}
                onClick={onClick}
                type="button"
            >
                <div />
                <div />
                <div />
            </button>
        </div>
    );

    if (!isOpen) return Button;

    const portal = document.querySelector('#portal');
    if (!portal) return Button;

    let path = '';

    if (typeof window !== 'undefined') {
        path = window.location.pathname;
    }

    const onClickLogout = async () => {
        await logout();
    };

    const Menu = (
        <div className={clsx('bg-body h-full w-3/4 sm:w-1/2', styles.shadow)}>
            <div className="flex h-full flex-col">
                <div
                    className={clsx(
                        'bg-step flex h-[3.4rem] items-center justify-between border-b border-grey-200 pr-4 dark:border-grey-800',
                        styles.shadow
                    )}
                >
                    <div
                        className="truncate px-5 font-semibold"
                        data-dd-privacy="mask"
                    >
                        {fullName}
                    </div>
                    {Button}
                </div>
                {fullName ? (
                    <>
                        {path.includes('/reservations') ? (
                            <div className="text-golden mt-4 w-full py-1 pl-5">
                                {t('user.reservations.title')}
                            </div>
                        ) : (
                            <Link
                                className="link-item mt-4 block w-full py-1 pl-5"
                                onClick={onClick}
                                to="/account/reservations"
                            >
                                {t('user.reservations.title')}
                            </Link>
                        )}
                        {path.includes('/bookmarks') ? (
                            <div className="text-golden mt-4 w-full py-1 pl-5">
                                {t('bookmarks.title')}
                            </div>
                        ) : (
                            <Link
                                className="link-item mt-4 block w-full py-1 pl-5"
                                onClick={onClick}
                                to="/account/bookmarks"
                            >
                                {t('bookmarks.title')}
                            </Link>
                        )}
                        <Link
                            className={clsx(
                                'link-item mt-4 block w-full py-1 pl-5',
                                path.includes('/profile') && 'text-golden'
                            )}
                            onClick={onClick}
                            to="/account/profile"
                        >
                            {t('user.profile')}
                        </Link>
                        <button
                            className="link-item mt-4 block w-full py-1 pl-5 text-left"
                            onClick={onClickLogout}
                        >
                            {t('authentication.logout')}
                        </button>
                    </>
                ) : (
                    <>
                        <button
                            className="mt-4 block w-full py-1 pl-5 text-left"
                            onClick={() => setAuth('login')}
                            type="button"
                        >
                            {t('authentication.login.title')}
                        </button>
                        <button
                            className="mt-4 block w-full py-1 pl-5 text-left"
                            onClick={() => setAuth('signup')}
                            type="button"
                        >
                            {t('authentication.signup.title')}
                        </button>
                    </>
                )}
                <div
                    className={`bg-step mt-4 flex w-full flex-1 flex-col gap-y-3 border-t border-grey-200 pl-3 pt-5 dark:border-grey-900 ${styles.shadowUp} `}
                >
                    <ReactRouterLink
                        className="link-item block w-full pl-3 text-left"
                        rel="noreferrer"
                        target="_blank"
                        to={
                            locale === 'ja'
                                ? (process.env.CUSTOMER_HELP_FAQ_JA as string)
                                : (process.env.CUSTOMER_HELP_FAQ_EN as string)
                        }
                    >
                        <FontAwesomeIcon icon={['far', 'question-circle']} />
                        <span className="ml-2">{t('staticPages.faq')}</span>
                    </ReactRouterLink>
                    <LocaleSelector classNameSelect="w-max bg-step" />
                    <div className="flex items-center pl-3 text-sm">
                        <DarkModeToggle />
                        <span className="ml-2 hidden text-base dark:inline">
                            {t('theme.darkMode')}
                        </span>
                        <span className="ml-2 text-base dark:hidden">
                            {t('theme.lightMode')}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );

    return createPortal(Menu, portal);
};

export default MobileUserMenu;

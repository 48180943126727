import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
//import BreakpointWrapper from 'components/BreakpointWrapper';
import InputText from 'components/Form/InputText';
import SubmitButton from 'components/Form/SubmitButton';
import {SearchPropertiesDocument, SearchPropertiesQuery} from 'gql/generated';
import {SearchDateRange, SearchValues} from 'types/search';
import {getSearchDateRange} from 'utils/date';
import AreaSelect from './AreaSelect';
import CuisineSelect from './CuisineSelect';
import DateSelector, {DateSelectorChangeEvent} from './DateSelector';
import PartySizeSelect from './PartySizeSelect';
import PriceRangeSelect from './PriceRangeSelect';
import RealTimeBooking from './RealTimeBooking';
import SeatingSelect from './SeatingSelect';
import ServiceSelector from './ServiceSelect';

export type SearchProps = {
    className?: string;
    isHomePage?: boolean;
    onSearch?: (formData: SearchValues) => void;
    searchDateRange?: SearchDateRange;
    searchValues?: SearchValues;
};

const Search: FC<SearchProps> = ({
    className,
    isHomePage,
    onSearch = () => {},
    searchDateRange = getSearchDateRange(),
    searchValues,
}) => {
    const {t} = useTranslation();

    const methods = useForm<SearchValues>({
        defaultValues: searchValues,
    });

    const {getValues, handleSubmit, setValue} = methods;

    const onDateChange = (event: DateSelectorChangeEvent) => {
        setValue('date', event.value);
    };

    const onSubmit = (formData: SearchValues) => {
        onSearch({...formData, keyword: formData.keyword?.trim()});
    };

    const {data} = useQuery<SearchPropertiesQuery>(SearchPropertiesDocument);

    const {areas, cuisines} = data || {areas: [], cuisines: []};

    return (
        <FormProvider {...methods}>
            <form
                className={`z-50 grid gap-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5
                    ${isHomePage ? 'grid-cols-6' : 'grid-cols-2 sm:grid-cols-3'}
                    ${className}`}
                onSubmit={handleSubmit(onSubmit)}
            >
                <DateSelector
                    className={`${
                        isHomePage ? 'col-span-3 sm:col-span-1' : ''
                    }`}
                    id="date-selector"
                    isClearable={true}
                    maxDate={searchDateRange.max}
                    minDate={searchDateRange.min}
                    onChange={onDateChange}
                    value={getValues('date')}
                />
                <AreaSelect
                    areas={areas}
                    className={`${
                        isHomePage ? 'col-span-3 sm:col-span-1' : ''
                    }`}
                />
                <PartySizeSelect
                    className={`${
                        isHomePage ? 'col-span-2 sm:col-span-1' : ''
                    }`}
                />
                {!isHomePage && (
                    <>
                        <CuisineSelect cuisines={cuisines} />
                        <ServiceSelector />
                        <PriceRangeSelect />
                        <SeatingSelect />
                        <RealTimeBooking />
                    </>
                )}
                <InputText
                    className={`${
                        isHomePage ? 'col-span-4' : 'col-span-full'
                    } sm:col-span-1 md:col-span-2 lg:col-span-1`}
                    classNameInput="search-input"
                    data-dd-privacy="allow"
                    icon={['fas', 'magnifying-glass']}
                    name="keyword"
                    placeholder={t('search.placeholderKeyword')}
                />
                <SubmitButton
                    className={clsx(
                        'col-span-full',
                        isHomePage
                            ? 'dark:border-grey-700 lg:col-span-1'
                            : 'md:col-span-2 lg:col-span-1'
                    )}
                    data-dd-privacy="allow"
                >
                    {t('search.label')}
                </SubmitButton>
            </form>
        </FormProvider>
    );
};

export default Search;

import {Route} from 'react-router-dom';
import PageLayout from 'components/Layout/PageLayout';
import {Analytics, PageName} from 'pages/Analytics';
import ForgotPassword from 'pages/Authentication/ForgotPassword';
import ResendConfirmation from 'pages/Authentication/ResendConfirmation';
import ResetPassword from 'pages/Authentication/ResetPassword';
import SignupConfirmation from 'pages/Authentication/SignupConfirmation';
import {UnlockAccount} from 'pages/Authentication/UnlockAccount';
import {CanonicalUrl} from 'pages/CanonicalUrl';

const AuthenticationRoutes = () => (
    <Route element={<PageLayout isSessionDisabled={true} />}>
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.UnlockAccount}>
                        <UnlockAccount />
                    </Analytics>
                </CanonicalUrl>
            }
            path="unlock"
        />
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.ConfirmAccount}>
                        <SignupConfirmation />
                    </Analytics>
                </CanonicalUrl>
            }
            path="confirmation"
        />
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.ResendConfirmation}>
                        <ResendConfirmation />
                    </Analytics>
                </CanonicalUrl>
            }
            path="resend-confirmation"
        />
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.ForgotPassword}>
                        <ForgotPassword />
                    </Analytics>
                </CanonicalUrl>
            }
            path="forgot-password"
        />
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.ResetPassword}>
                        <ResetPassword />
                    </Analytics>
                </CanonicalUrl>
            }
            path="reset-password"
        />
    </Route>
);

export default AuthenticationRoutes;

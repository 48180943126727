import {FC, ReactNode} from 'react';
import {Helmet} from 'react-helmet';
import {getCleanUrl} from 'utils/environment';

type Props = {
    // eslint-disable-next-line react/boolean-prop-naming
    blockRobots?: boolean;
    children: ReactNode;
};

export const CanonicalUrl: FC<Props> = ({blockRobots = false, children}) => {
    return (
        <>
            <Helmet>
                {blockRobots ? (
                    <meta content="noindex" name="robots" />
                ) : (
                    <link href={getCleanUrl()} rel="canonical" />
                )}
            </Helmet>
            {children}
        </>
    );
};

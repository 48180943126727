/* eslint-disable react/boolean-prop-naming */
import {FC, RefObject, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import BookmarkButton from 'components/BookmarkButton';
import FallbackImage from 'components/FallbackImage';
import Link from 'components/Link';
import {VenueSearchResultFragment} from 'gql/generated';
import useComponentRect from 'hooks/useComponentRect';
import {useUser} from 'state/user';
import VenueArea from '../VenueArea';
import VenueCuisines from '../VenueCuisines';
import VenuePriceRanges from '../VenuePriceRanges';
import VenueTags from '../VenueTags';
import BookmarkRemoveButton from './BookmarkRemoveButton';

export type VenueResultCardProps = {
    className?: string;
    isBookmark?: boolean;
    minimal?: boolean;
    search?: string;
    venue: VenueSearchResultFragment;
};

const VenueResultCard: FC<VenueResultCardProps> = ({
    className,
    isBookmark,
    minimal,
    search = '',
    venue,
}) => {
    const {t} = useTranslation();
    const {
        blurb,
        cuisines,
        id,
        imageUrls,
        isPublished,
        limitedScopeUrlHash,
        name,
        priceRanges,
        realTimeBooking,
        thumbnail,
    } = venue;

    const user = useUser();

    const nameRef: RefObject<HTMLHeadingElement> = useRef(null);
    const {height} = useComponentRect(nameRef, name);

    const blurbClamp = height > 25 ? 'line-clamp-4' : 'line-clamp-5';
    const venueLinkId = isPublished ? id : limitedScopeUrlHash;

    const [imageError, setImageError] = useState(false);

    const image = thumbnail || imageUrls?.[0];

    return (
        <Link
            aria-label={name}
            className={clsx(
                'border-venue-result bg-step-dark-only link-item flex select-none flex-col overflow-hidden rounded',
                className
            )}
            to={`/restaurants/${venueLinkId}${search}`}
        >
            <figure className="relative">
                {image && !imageError ? (
                    <div className="w-full pb-[56.5%]">
                        <picture>
                            <img
                                alt={t('imageCarousel.imageName', {name})}
                                className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md rounded-b-none object-cover object-center"
                                loading="lazy"
                                onError={() => setImageError(true)}
                                src={image}
                            />
                        </picture>
                    </div>
                ) : (
                    <div className="w-full pb-[56.5%]">
                        <picture>
                            <FallbackImage
                                alt={t('imageCarousel.imageName', {name})}
                                className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md rounded-b-none object-cover object-center"
                            />
                        </picture>
                    </div>
                )}
                {(isBookmark || !minimal) && (
                    <div className="absolute inset-0">
                        {!isBookmark && user && (
                            <BookmarkButton
                                className="absolute -bottom-4 right-3 z-[3]"
                                venue={venue}
                            />
                        )}
                        {!minimal && (
                            <VenueTags
                                className="absolute -bottom-2 left-3 z-[3]"
                                realTimeBooking={realTimeBooking}
                            />
                        )}
                    </div>
                )}
            </figure>
            <div
                className={clsx(
                    'z-[2]',
                    minimal ? 'px-4 pt-4' : 'p-4',
                    isBookmark && 'flex h-full flex-col'
                )}
            >
                <div
                    ref={nameRef}
                    className={clsx(
                        'line-clamp-2 font-semibold leading-tight',
                        isBookmark && 'flex-none'
                    )}
                >
                    {name}
                </div>
                {height > 0 && (
                    <div
                        className={clsx(
                            'mt-1 flex flex-col gap-1',
                            isBookmark && 'flex-1'
                        )}
                    >
                        <VenueArea venue={venue} />
                        <VenueCuisines cuisines={cuisines} />
                        <VenuePriceRanges
                            className={clsx(minimal ? 'pb-4 pt-2' : 'py-2')}
                            priceRanges={priceRanges}
                        />
                        {!minimal && (
                            <p className={clsx('text-sm', blurbClamp)}>
                                {blurb}
                            </p>
                        )}
                    </div>
                )}
                {isBookmark && (
                    <div className="mt-4 flex flex-none justify-center">
                        <BookmarkRemoveButton venueId={id} />
                    </div>
                )}
            </div>
        </Link>
    );
};

export default VenueResultCard;

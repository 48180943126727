import {FC, useCallback, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import BreakpointWrapper from 'components/BreakpointWrapper';
import Button from 'components/Button';
import MutationStatus from 'components/Form/MutationStatus';
import SubmitButton from 'components/Form/SubmitButton';
import PaymentMethod from 'components/PaymentMethod';
import {
    CreditCard,
    ReservationCreateInput,
    ReserveVenueFragment,
} from 'gql/generated';
import {useAnalytics} from 'hooks/useAnalytics';
import {useStripeForm} from 'hooks/useStripeForm';
import {PageName} from 'pages/Analytics';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {ReservePaymentFormData, ReservePaymentInput} from 'types/reserve';
import CreditCardPayment from './CreditCardPayment';

const getDefaultCreditCardId = (
    input: ReservationCreateInput,
    creditCards: CreditCard[]
) => {
    if (input.creditCardId) {
        return input.creditCardId;
    }

    if (creditCards[0]?.id) {
        return creditCards[0].id;
    }

    return 'new';
};

export type ReservePaymentProps = {
    creditCards: CreditCard[];
    input: ReservationCreateInput;
    onBack: () => void;
    onSubmit: (value: ReservePaymentInput) => void;
    venue: ReserveVenueFragment;
};

const ReservePayment: FC<ReservePaymentProps> = ({
    creditCards,
    input,
    onBack,
    onSubmit,
}) => {
    const {t} = useTranslation();
    const {showToast} = useToastNotification();

    useAnalytics(`${PageName.Reserve}: Payment`);

    const methods = useForm<ReservePaymentFormData>({
        defaultValues: {
            creditCardId: getDefaultCreditCardId(input, creditCards),
            creditCardName: '',
            ryoshushoName: input.ryoshushoName || '',
        },
    });

    const {clearErrors, handleSubmit, setValue, watch} = methods;

    const [creditCardId, creditCardName] = watch([
        'creditCardId',
        'creditCardName',
    ]);

    const onRemove = useCallback(
        (id: string) => {
            if (creditCardId === id) {
                // if the removed credit card was the selected credit card,
                // set creditCardId to the next available credit card
                setValue(
                    'creditCardId',
                    creditCards.find((card) => card.id !== id)?.id || 'new'
                );
            }
        },
        [creditCardId, creditCards, setValue]
    );

    const creditCardOptions = useMemo(() => {
        return creditCards.map((card) => ({
            label: <PaymentMethod card={card} onRemove={onRemove} />,
            value: card.id,
        }));
    }, [creditCards, onRemove]);

    // when a credit card is added, select it and clear the creditCardName field
    const onSuccess = useCallback(
        (paymentMethodId: string) => {
            setValue('creditCardId', paymentMethodId);
            setValue('creditCardName', '');
        },
        [setValue]
    );

    // display Stripe errors with adding a new card
    const onError = useCallback(
        (message: string) => {
            if (message) {
                const errorMessage =
                    message === '500' ? t('error.500.message') : message;

                showToast({
                    message: errorMessage,
                    type: ToastType.Error,
                });
            } else {
                clearErrors('serverError');
            }
        },
        [clearErrors, showToast, t]
    );

    const {onAddCardSubmit, requestAndConfirmSetupIntent} = useStripeForm();

    const isNewPaymentMethod = creditCardId === 'new';

    const onPaymentMethodSubmit = async (formData: ReservePaymentFormData) => {
        if (isNewPaymentMethod) {
            showToast({
                message: t('reserve.payment.required'),
                type: ToastType.Error,
            });

            return;
        }

        const selectedCard = creditCards.find(({id}) => id === creditCardId);

        if (selectedCard === undefined) {
            return;
        }

        const {ryoshushoName} = formData;

        if (selectedCard.confirmationNeeded) {
            await requestAndConfirmSetupIntent({
                callback: () => {
                    onSubmit({
                        creditCardId,
                    });
                },
                creditCardId,
            });
        } else {
            onSubmit({
                creditCardId,
                ryoshushoName,
            });
        }
    };

    // adding a new card is handled by a different process than submitting the selected creditCardId
    const onFormSubmit = isNewPaymentMethod
        ? () =>
              onAddCardSubmit({
                  creditCardName,
                  onError,
                  onSuccess,
              })
        : onPaymentMethodSubmit;

    return (
        <FormProvider {...methods}>
            <form
                className="md:border-outline grid grid-rows-mobile-2-reverse overflow-hidden md:mt-4 md:block md:overflow-auto md:rounded-md md:border md:p-5"
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <BreakpointWrapper breakpoint="md" className="overflow-y-auto">
                    <div className="space-y-4">
                        <CreditCardPayment
                            creditCardOptions={creditCardOptions}
                            onError={onError}
                        />
                        {!isNewPaymentMethod && <MutationStatus />}
                    </div>
                </BreakpointWrapper>
                <div className="shadow-top md:bg-body z-20 p-4 md:mt-4 md:flex md:justify-between md:px-0 md:pb-0 md:shadow-none">
                    <Button
                        className="mt-0 hidden w-auto md:block"
                        kind="secondary"
                        onClick={onBack}
                    >
                        {t('back')}
                    </Button>
                    {isNewPaymentMethod ? (
                        <Button className="w-full md:w-auto" disabled={true}>
                            {t('reserve.payment.submit')}
                        </Button>
                    ) : (
                        <SubmitButton className="w-full md:w-auto">
                            {t('reserve.payment.submit')}
                        </SubmitButton>
                    )}
                </div>
            </form>
        </FormProvider>
    );
};

export default ReservePayment;

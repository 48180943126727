import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import clsx from 'clsx';
import Link from 'components/Link';

export type FooterLegalProps = {
    className?: string;
};

const FooterLegal: FC<FooterLegalProps> = ({className}) => {
    const {t} = useTranslation();

    return (
        <div className={clsx('px-5 pb-5 sm:p-0', className)}>
            <hr className="sm:mt-5" />
            <div className="flex flex-col-reverse items-center pt-5 sm:pb-5 lg:flex-row lg:items-center lg:justify-between">
                <div className="text-xs">
                    &copy; {new Date().getFullYear()}, {t('siteName')}
                </div>
                <hr className="my-5 w-full lg:hidden" />
                <div className="space-x-8 whitespace-nowrap text-xs lg:col-span-2 lg:text-right">
                    <Link
                        className="link-item hover:underline"
                        to="/company-profile"
                    >
                        {t('staticPages.companyProfile')}
                    </Link>
                    <Link className="link-item hover:underline" to="/terms">
                        {t('staticPages.termsOfUse')}
                    </Link>
                    <Link className="link-item hover:underline" to="/privacy">
                        {t('staticPages.privacyPolicy')}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FooterLegal;

import {FC, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import BreakpointWrapper from 'components/BreakpointWrapper';
import MutationStatus from 'components/Form/MutationStatus';
import SubmitButton from 'components/Form/SubmitButton';
import MobileReservationInformation from 'components/ReservationInformation/MobileReservationInformation';
import {
    OnBehalfOfGuestInput,
    ReservationCreateInput,
    ReserveUserProfileFragment,
    ReserveVenueFragment,
    UserGuestInput,
    VisitFrequency,
    VisitPurpose,
} from 'gql/generated';
import {useAnalytics} from 'hooks/useAnalytics';
import {PageName} from 'pages/Analytics';
import {useLocale} from 'state/locale';
import {ReserveDetailsFormData, ReserveDetailsInput} from 'types/reserve';
import {checkIfWaitlist} from 'utils/params';
import {fromPhoneNumber, toPhoneNumber} from 'utils/phone';
import ContactInformation from './ContactInformation';
import Hotel from './Hotel';
import Preferences from './Preferences';
import SpecialRequestOption from './SpecialRequestOption';
import VisitFrequencySelect from './VisitFrequencySelect';
import VisitPurposeSelect from './VisitPurposeSelect';

const DEFAULT_FORM_VALUES = {
    allergy: '',
    countryCode: '',
    email: '',
    firstName: '',
    firstNameKana: '',
    hotel: '',
    hotelGuestName: '',
    hotelName: '',
    lastName: '',
    lastNameKana: '',
    memo: '',
    specialRequestOptionId: '',
    specialRequestOptionMessage: '',
    tel: '',
};

export type ReserveDetailsProps = {
    input: ReservationCreateInput;
    onSubmit: (value: ReserveDetailsInput) => void;
    user: ReserveUserProfileFragment;
    venue: ReserveVenueFragment;
};

const ReserveDetails: FC<ReserveDetailsProps> = ({
    input,
    onSubmit,
    user,
    venue,
}) => {
    const {t} = useTranslation();
    const locale = useLocale();
    const [searchParams] = useSearchParams();
    const isWaitlist = checkIfWaitlist(searchParams);

    useAnalytics(`${PageName.Reserve}: Details`);

    const defaultValues = useMemo(() => {
        // details for myself have been submitted
        if (input.userGuest) {
            return {
                ...DEFAULT_FORM_VALUES,
                allergy: input.userGuest.allergy,
                email: '',
                firstNameKana: input.userGuest.firstNameKana,
                lastNameKana: input.userGuest.lastNameKana,
                onBehalfOf: 'myself',
                visitFrequency: input.visitFrequency,
                visitPurpose: input.visitPurpose,
                ...fromPhoneNumber(input.userGuest.phoneNumber),
            } as ReserveDetailsFormData;
        }

        // details for onBehalfOf have been submitted
        if (input.onBehalfOfGuest) {
            return {
                ...DEFAULT_FORM_VALUES,
                allergy: input.onBehalfOfGuest.allergy,
                email: input.onBehalfOfGuest.email,
                firstNameKana: input.onBehalfOfGuest.firstNameKana,
                lastNameKana: input.onBehalfOfGuest.lastNameKana,
                onBehalfOf: 'anotherPerson',
                visitFrequency: input.visitFrequency,
                visitPurpose: input.visitPurpose,
                ...fromPhoneNumber(input.onBehalfOfGuest.phoneNumber),
            } as ReserveDetailsFormData;
        }

        // details have not yet been submitted
        return {
            ...DEFAULT_FORM_VALUES,
            allergy: '',
            email: user.email || '',
            firstNameKana: user.firstNameKana || '',
            lastNameKana: user.lastNameKana || '',
            onBehalfOf: 'myself',
            visitFrequency: VisitFrequency.FirstVisit,
            visitPurpose: '',
            ...fromPhoneNumber(user.phoneNumber),
        } as ReserveDetailsFormData;
    }, [input, user]);

    const methods = useForm<ReserveDetailsFormData>({defaultValues});

    const {handleSubmit, reset, watch} = methods;

    const onBehalfOf = watch('onBehalfOf');

    useEffect(() => {
        // toggling onBehalfOf clears values
        if (onBehalfOf === 'myself') {
            reset(defaultValues);
        } else {
            reset({
                ...DEFAULT_FORM_VALUES,
                onBehalfOf: 'anotherPerson',
                visitFrequency: VisitFrequency.FirstVisit,
                visitPurpose: '',
            });
        }
    }, [defaultValues, onBehalfOf, reset]);

    const onFormSubmit = (formData: ReserveDetailsFormData) => {
        const {
            allergy,
            email,
            firstName,
            firstNameKana,
            hotel,
            hotelGuestName,
            hotelName,
            lastName,
            lastNameKana,
            memo,
            specialRequestOptionId,
            specialRequestOptionMessage,
            tel,
            visitFrequency,
            visitPurpose,
        } = formData;

        const userGuest: UserGuestInput = {
            firstNameKana,
            lastNameKana,
            phoneNumber: toPhoneNumber(tel),
        };
        const onBehalfOfGuest: OnBehalfOfGuestInput = {
            ...userGuest,
            email,
            firstName,
            firstNameKana: firstNameKana || '',
            lastName,
            lastNameKana: lastNameKana || '',
        };

        const userKey =
            onBehalfOf === 'myself' ? 'userGuest' : 'onBehalfOfGuest';
        const userValue = onBehalfOf === 'myself' ? userGuest : onBehalfOfGuest;
        const userKeyClear =
            userKey === 'userGuest' ? 'onBehalfOfGuest' : 'userGuest';

        onSubmit({
            allergy,
            hotelGuestName: hotel === 'yes' ? hotelGuestName : '',
            hotelName: hotel === 'yes' ? hotelName : '',
            memo,
            specialRequestOptionId,
            specialRequestOptionMessage,
            [userKey]: userValue,
            [userKeyClear]: undefined,
            visitFrequency: visitFrequency || VisitFrequency.FirstVisit,
            visitPurpose: visitPurpose || VisitPurpose.Other,
        });
    };

    return (
        <FormProvider {...methods}>
            <form
                className="md:border-outline grid grid-rows-mobile-2-reverse overflow-hidden md:mt-4 md:block md:overflow-auto md:rounded-md md:border md:p-5"
                onSubmit={handleSubmit(onFormSubmit)}
            >
                <BreakpointWrapper
                    breakpoint="md"
                    className="overflow-y-auto pt-4"
                >
                    <MobileReservationInformation />
                    <h3 className="md:border-outline mt-2 px-4 pb-1.5 md:border-b md:px-0">
                        {t('reserve.details.contactInformation')}
                    </h3>
                    <div className="bg-step space-y-6 px-4 py-3 md:mt-2">
                        <ContactInformation user={user} />
                        <VisitPurposeSelect />
                        <VisitFrequencySelect venue={venue} />
                        {locale === 'en' && <Hotel />}
                    </div>
                    {!isWaitlist && <SpecialRequestOption venue={venue} />}
                    <Preferences />
                    <MutationStatus />
                </BreakpointWrapper>
                <div className="shadow-top md:bg-body z-20 p-4 md:mt-4 md:flex md:justify-end md:px-0 md:pb-0 md:shadow-none">
                    <SubmitButton className="w-full md:w-auto">
                        {t('reserve.details.submit')}
                    </SubmitButton>
                </div>
            </form>
        </FormProvider>
    );
};

export default ReserveDetails;

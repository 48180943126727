import {Route} from 'react-router-dom';
import Server404 from 'components/Errors/Server404';
import PageLayout from 'components/Layout/PageLayout';
import {Analytics, PageName} from 'pages/Analytics';
import {CanonicalUrl} from 'pages/CanonicalUrl';
import HomePage from 'pages/Home';
import SearchPage from 'pages/Search';
import VenuePage from 'pages/Venue';

const PublicRoutes = () => (
    <Route element={<PageLayout showBanner={true} />}>
        <Route
            element={
                <CanonicalUrl>
                    <Analytics pageName={PageName.Top}>
                        <HomePage />
                    </Analytics>
                </CanonicalUrl>
            }
            index={true}
        />
        <Route element={<Server404 />} path="*" />
        <Route path="restaurants/*">
            <Route element={<Server404 />} path="*" />
            {/* Canonical urls are handled by the Search Page itself */}
            <Route
                element={
                    <Analytics pageName={PageName.Search}>
                        <SearchPage />
                    </Analytics>
                }
                index={true}
            />
            {/* Venue pages don't need the <Analytics /> wrapper because they handle the tracking by themselves */}
            <Route
                element={
                    <CanonicalUrl>
                        <VenuePage />
                    </CanonicalUrl>
                }
                path=":id"
            />
            <Route
                element={
                    <CanonicalUrl blockRobots={true}>
                        <VenuePage isSecretSeat={true} />
                    </CanonicalUrl>
                }
                path=":id/secret_seat"
            />
        </Route>
    </Route>
);

export default PublicRoutes;

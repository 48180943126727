import {FC, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useMutation} from '@apollo/client';
import clsx from 'clsx';
import Form from 'components/Form';
import RadioButtons from 'components/Form/RadioButtons';
import SubmitButton from 'components/Form/SubmitButton';
import TextArea from 'components/Form/TextArea';
import {
    QuestionnaireSatisfactionRating,
    ReservationBaseFragment,
    ReservationQuestionnaireFragment,
    ReservationsDocument,
    SubmitQuestionnaireDocument,
} from 'gql/generated';
import {ToastType, useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';

const RATINGS: QuestionnaireSatisfactionRating[] = [
    QuestionnaireSatisfactionRating.VerySatisfied,
    QuestionnaireSatisfactionRating.Satisfied,
    QuestionnaireSatisfactionRating.Dissatisfied,
    QuestionnaireSatisfactionRating.VeryDissatisfied,
];

type FeedbackFormData = FormData<{
    feedback: string;
    satisfactionRating: string;
}>;

type UserReservationQuestionnaireProps = {
    reservation: ReservationBaseFragment & ReservationQuestionnaireFragment;
};

export const Questionnaire: FC<UserReservationQuestionnaireProps> = ({
    reservation,
}) => {
    const {t} = useTranslation();
    const {id, questionnaire} = reservation;
    const {showToast, toastWrapper} = useToastNotification();

    const methods = useForm<FeedbackFormData>({
        defaultValues: {
            feedback: questionnaire?.feedback || '',
            satisfactionRating: questionnaire?.satisfactionRating || '',
        },
    });
    const {handleSubmit, watch} = methods;
    const feedback = watch('feedback');

    const satisfactionRating = watch('satisfactionRating');

    const [submit, {loading}] = useMutation(SubmitQuestionnaireDocument, {
        refetchQueries: [ReservationsDocument],
        update: (cache, _, context) => {
            const reservationId = cache.identify({
                __typename: 'Reservation',
                id,
            });
            cache.modify({
                fields: {
                    questionnaire: () => ({
                        __typename: 'Questionnaire',
                        // we read these values from the mutation variables because
                        // methods.getValues('feedback') always returns undefined here
                        feedback: context.variables?.feedback,
                        satisfactionRating:
                            context.variables?.satisfactionRating,
                    }),
                },
                id: reservationId,
            });
        },
    });

    const options = useMemo(
        () =>
            RATINGS.map((value) => ({
                label: t(
                    `user.reservations.questionnaire.satisfactionRating.${value}`
                ),
                value,
            })),
        [t]
    );

    const onSubmit = async (formData: FeedbackFormData) => {
        await toastWrapper({
            onFulfilled: () => {
                showToast({
                    message: t('user.reservations.questionnaire.success'),
                    type: ToastType.Success,
                });
            },
            promise: submit({
                variables: {
                    feedback: formData.feedback,
                    id,
                    satisfactionRating: formData.satisfactionRating,
                },
            }),
        });
    };

    return (
        <FormProvider {...methods}>
            <div className="bg-step col-span-full -mt-1 rounded-md p-4">
                <h4 className="border-outline border-b pb-2 font-semibold">
                    {t('user.reservations.questionnaire.header')}
                </h4>
                <Form
                    className="mt-4 grid grid-cols-1 gap-4 md:grid-cols-2"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="col-span-full md:col-span-1">
                        <RadioButtons
                            id={id}
                            label={t('user.reservations.questionnaire.rating')}
                            name="satisfactionRating"
                            options={options}
                            size="sm"
                        />
                    </div>
                    <div className="col-span-full mt-3 grid grid-cols-1 gap-4 md:col-span-1 md:mt-0">
                        <TextArea
                            className={clsx(
                                !satisfactionRating && 'opacity-50'
                            )}
                            disableNewLines={true}
                            disabled={!satisfactionRating || loading}
                            hideMaxLength={true}
                            label={t('user.reservations.questionnaire.label')}
                            maxLength={64_000}
                            name="feedback"
                            required={t('form.thisFieldIsRequired')}
                            rows={3}
                        />
                        <div className="place-self-end">
                            <SubmitButton
                                className={clsx(
                                    (!satisfactionRating ||
                                        !feedback ||
                                        feedback === '') &&
                                        'opacity-50'
                                )}
                                disabled={!satisfactionRating || !feedback}
                            />
                        </div>
                    </div>
                </Form>
            </div>
        </FormProvider>
    );
};

import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {ReservationCreateInput, ReserveVenueFragment} from 'gql/generated';
import {checkIfWaitlist} from 'utils/params';

export type SummaryDetailsProps = {
    input: ReservationCreateInput;
    venue: ReserveVenueFragment;
};

const SummaryDetails: FC<SummaryDetailsProps> = ({input, venue}) => {
    const {t} = useTranslation();

    const [searchParams] = useSearchParams();
    const isWaitlist = checkIfWaitlist(searchParams);

    const {
        allergy,
        hotelGuestName,
        hotelName,
        memo,
        specialRequestOptionId,
        specialRequestOptionMessage,
        visitFrequency,
        visitPurpose,
    } = input;

    const specialRequest = venue.specialRequestOptions.find(
        (option) => option.id === specialRequestOptionId
    )?.title;

    const detailsTitle = t(
        isWaitlist
            ? 'reserve.details.title.waitlist'
            : venue.realTimeBooking
            ? 'reserve.details.title.realTimeBooking'
            : 'reserve.details.title.reservationRequest'
    );

    return (
        <>
            <h3 className="border-outline mt-4 border-t pt-4 md:mt-6">
                {detailsTitle}
            </h3>
            <div className="mt-3 grid gap-5 md:grid-cols-2">
                <div>
                    <div className="text-xs">
                        {t('reserve.details.visitPurpose.label')}
                    </div>
                    <div className="text-sm font-semibold">
                        {t(`reserve.details.visitPurpose.${visitPurpose}`)}
                    </div>
                </div>
                <div>
                    <div className="text-xs">
                        {t('reserve.details.visitFrequency.summary', venue)}
                    </div>
                    <div className="text-sm font-semibold">
                        {t(`reserve.details.visitFrequency.${visitFrequency}`)}
                    </div>
                </div>
                {hotelName && (
                    <div>
                        <div className="text-xs">
                            {t('reserve.details.hotel.name')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {hotelName}
                        </div>
                    </div>
                )}
                {hotelGuestName && (
                    <div>
                        <div className="text-xs">
                            {t('reserve.details.hotel.guest')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {hotelGuestName}
                        </div>
                    </div>
                )}
                {specialRequest && (
                    <div className="md:col-span-2">
                        <div className="text-xs">
                            {t('reserve.details.specialRequestOption.label')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {specialRequest}
                        </div>
                    </div>
                )}
                {specialRequestOptionMessage && (
                    <div className="md:col-span-2">
                        <div className="text-xs">
                            {t('reserve.details.specialRequestOption.message')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {specialRequestOptionMessage}
                        </div>
                    </div>
                )}
                {allergy && (
                    <div className="md:col-span-2">
                        <div className="text-xs">
                            {t('reserve.details.allergy')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {allergy}
                        </div>
                    </div>
                )}
                {memo && (
                    <div className="md:col-span-2">
                        <div className="text-xs">
                            {t('reserve.details.memo')}
                        </div>
                        <div
                            className="text-sm font-semibold"
                            data-dd-privacy="mask"
                        >
                            {memo}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default SummaryDetails;

import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import Preference from 'components/Form/Preference';

const Preferences: FC = () => {
    const {t} = useTranslation();

    return (
        <fieldset className="mt-6">
            <h3 className="md:border-outline px-4 pb-1.5 md:border-b md:px-0">
                {t('reserve.details.preferences')}
            </h3>
            <div className="bg-step space-y-4 px-4 py-3 md:mt-2">
                <Preference
                    label={
                        <Trans
                            components={{
                                li: <li />,
                                ul: <ul className="list-disc pl-4" />,
                            }}
                        >
                            {t('reserve.details.allergyMultiLabel')}
                        </Trans>
                    }
                    maxLength={150}
                    name="allergy"
                    placeholder={t('reserve.details.allergyExample')}
                />
                <Preference
                    label={t('reserve.details.memo')}
                    name="memo"
                    placeholder={t('reserve.details.memo')}
                />
            </div>
        </fieldset>
    );
};

export default Preferences;

import {FC} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/client';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import Server404 from 'components/Errors/Server404';
import FallbackImage from 'components/FallbackImage';
import Form from 'components/Form';
import SubmitButton from 'components/Form/SubmitButton';
import TextArea from 'components/Form/TextArea';
import Link from 'components/Link';
import Spinner from 'components/Loaders/Spinner';
import {
    ReservationMessageDocument,
    ReservationMessageQuery,
    ReservationStatus as GqlReservationStatus,
    SendMessageDocument,
} from 'gql/generated';
import useCurrentBreakpoint from 'hooks/useCurrentBreakpoint';
import useNavigateLocale from 'hooks/useNavigateLocale';
import {useLocale} from 'state/locale';
import {useToastNotification} from 'state/toastNotification';
import {FormData} from 'types';
import {
    fromIOS8601DateTimeString,
    isAfterSixPmDayBefore,
    toFullDate,
    toISO8601Date,
    toTime,
} from 'utils/date';
import {ReservationStatus} from '../ListElement/ReservationStatus';

type MessageFormData = FormData<{message: string}>;

export const Message: FC = () => {
    const {t} = useTranslation();
    const locale = useLocale();
    const navigate = useNavigateLocale();
    const breakpoint = useCurrentBreakpoint();
    const showFullDate = breakpoint !== 'md';
    const {reservationId} = useParams();
    const {toastWrapper} = useToastNotification();

    const {data, error, loading} = useQuery<ReservationMessageQuery>(
        ReservationMessageDocument,
        {
            variables: {id: reservationId},
        }
    );

    const methods = useForm<MessageFormData>({defaultValues: {message: ''}});
    const {handleSubmit, watch} = methods;
    const message = watch('message');

    const [sendMessage, {loading: sendMessageLoading}] =
        useMutation(SendMessageDocument);

    const onSubmit = async (formData: MessageFormData) => {
        await toastWrapper({
            onFulfilled: () => navigate('/account/reservations'),
            promise: sendMessage({
                variables: {id: reservationId, message: formData.message},
            }),
            successMessage: t('user.reservations.sendMessageSuccess'),
        });
    };

    if (error) {
        return <Server404 />;
    }

    if (loading || !data?.reservation) {
        return <Spinner title="Loading Reservation" />;
    }

    const {dateTime, numberOfPeople, status, venue, waitingListRequest} =
        data.reservation;

    const parsedDateTime = fromIOS8601DateTimeString(dateTime) as Date;

    const reservationDate = showFullDate
        ? toFullDate(parsedDateTime, locale)
        : toISO8601Date(parsedDateTime);

    const guestIcon =
        numberOfPeople === 1
            ? 'user'
            : numberOfPeople === 2
            ? 'user-friends'
            : 'users';

    const venueImage = venue.thumbnail || venue.imageUrls[0];

    const displayForm =
        (status === GqlReservationStatus.Pending && !waitingListRequest) || // non-waiting pending can always send
        (status === GqlReservationStatus.Confirmed &&
            !isAfterSixPmDayBefore(new Date(dateTime))); // if confirmed for exists until the 6pm limit the day before

    const displayAlert =
        !displayForm &&
        status === GqlReservationStatus.Confirmed &&
        isAfterSixPmDayBefore(new Date(dateTime));

    return (
        <div className="flex flex-col gap-4 px-2 md:px-0">
            <h2 className="text-2xl">{t('user.reservations.inquiry.title')}</h2>
            <h3 className="text-xl">
                {t('user.reservations.inquiry.subTitle')}
            </h3>
            <div className="border-outline rounded-md border p-2.5 md:px-4 md:pb-4">
                <h3 className="border-outline hidden min-h-[2.8125rem] border-b pb-2.5 md:flex md:items-center md:justify-between md:gap-4">
                    <Link className="link-body" to={`/restaurants/${venue.id}`}>
                        <span className="line-clamp-2 font-semibold leading-snug">
                            {venue.name}
                        </span>
                    </Link>
                </h3>
                <div className="grid grid-cols-12 gap-4 md:mt-2.5">
                    <div className="col-span-4 lg:col-span-3">
                        <figure className="relative max-h-[12.375rem]">
                            {venueImage ? (
                                <div className="w-full pb-[56.5%]">
                                    <picture>
                                        <img
                                            alt={venue.name}
                                            className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                            src={venueImage}
                                        />
                                    </picture>
                                </div>
                            ) : (
                                <div className="w-full pb-[56.5%]">
                                    <picture>
                                        <FallbackImage
                                            alt={venue.name}
                                            className="absolute left-0 top-0 h-full max-h-[12.375rem] w-full overflow-hidden rounded-md object-cover object-center"
                                        />
                                    </picture>
                                </div>
                            )}
                        </figure>
                    </div>
                    <h3 className="col-span-8 md:hidden">
                        <Link
                            className="link-body"
                            to={`/restaurants/${venue.id}`}
                        >
                            <span className="line-clamp-3 font-semibold leading-snug">
                                {venue.name}
                            </span>
                        </Link>
                    </h3>
                    <hr className="col-span-full -mt-2 md:hidden" />
                    <div className="col-span-full -mt-2 grid grid-cols-1 items-start gap-4 md:col-span-8 md:mt-0 md:grid-cols-12 md:gap-2 lg:col-span-9 lg:gap-4">
                        <div className="col-span-1 grid grid-cols-1 gap-3.5 md:col-span-4 md:pt-3 lg:col-span-5">
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['far', 'calendar']}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {reservationDate}
                                </span>
                            </div>
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['fas', 'clock']}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {waitingListRequest ? (
                                        <>
                                            {/* Backend issue: we need to use reservation.dateTime
                                            instead of waitingListRequest.dateTimeRangeStart
                                            https://github.com/pocket-concierge/pokeme_rails/pull/9854#issuecomment-1384042575
                                        */}
                                            {toTime(parsedDateTime, locale)}
                                            &nbsp;-&nbsp;
                                            {toTime(
                                                fromIOS8601DateTimeString(
                                                    waitingListRequest.dateTimeRangeEnd
                                                ) as Date,
                                                locale
                                            )}
                                        </>
                                    ) : (
                                        toTime(parsedDateTime, locale)
                                    )}
                                </span>
                            </div>
                            <div className="flex items-center text-sm">
                                <div className="w-5 text-center">
                                    <FontAwesomeIcon
                                        className="icon"
                                        icon={['fas', guestIcon]}
                                    />
                                </div>
                                <span className="ml-2.5">
                                    {t('reservation.guestCount', {
                                        count: numberOfPeople,
                                    })}
                                </span>
                            </div>
                        </div>
                        <ReservationStatus
                            className="col-span-1 md:col-span-8 lg:col-span-7"
                            status={status}
                            waitlistDeadline={waitingListRequest?.deadline}
                        />
                    </div>
                    <div className="col-span-full">
                        {displayForm && (
                            <FormProvider {...methods}>
                                <Form
                                    className="bg-step col-span-full mt-2 gap-y-4 rounded-md p-4"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <TextArea
                                        disabled={sendMessageLoading}
                                        hideMaxLength={true}
                                        label={t(
                                            'user.reservations.sendMessage'
                                        )}
                                        maxLength={64_000}
                                        name="message"
                                        required={true}
                                        rows={3}
                                    />
                                    <div className="flex justify-between">
                                        <Button
                                            className="min-h-[42px] shrink"
                                            disabled={sendMessageLoading}
                                            kind="secondary"
                                            onClick={() =>
                                                navigate(
                                                    '/account/reservations'
                                                )
                                            }
                                        >
                                            {t(
                                                'user.reservations.button.cancel'
                                            )}
                                        </Button>
                                        <SubmitButton
                                            disabled={message === ''}
                                            loading={t(
                                                'user.reservations.sendMessage'
                                            )}
                                        />
                                    </div>
                                </Form>
                            </FormProvider>
                        )}
                        {displayAlert && (
                            <div
                                className="bg-step col-span-full flex flex-row items-center rounded-md border border-orange-800 p-1 text-sm dark:border-orange-700 md:text-base"
                                role="alert"
                            >
                                <FontAwesomeIcon
                                    className="pr-2 text-orange-800 dark:text-orange-700"
                                    icon={['fas', 'exclamation-circle']}
                                    size="1x"
                                />
                                <div className="text-sub text-center">
                                    {t(
                                        'user.reservations.cancelDayBeforeInformation',
                                        {
                                            tel: venue.phoneNumber,
                                        }
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

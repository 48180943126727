import {Dispatch, FC, SetStateAction, useState} from 'react';
import Stripe from 'components/Stripe';
import {
    Course,
    CreditCard,
    ReservationCreateInput,
    ReserveUserProfileFragment,
    ReserveVenueFragment,
    VisitFrequency,
    VisitPurpose,
} from 'gql/generated';
import useScrollToTop from 'hooks/useScrollToTop';
import {ReservationValues} from 'types/reservation';
import {ReserveDetailsInput, ReservePaymentInput} from 'types/reserve';
import ReserveDetails from './ReserveDetails';
import ReservePayment from './ReservePayment';
import ReserveSteps from './ReserveSteps';
import ReserveSummary from './ReserveSummary';

type ReserveFormProps = {
    course: Course;
    creditCards: CreditCard[];
    onConfirm: Dispatch<SetStateAction<string>>;
    user: ReserveUserProfileFragment;
    values: ReservationValues;
    venue: ReserveVenueFragment;
};

const ReserveForm: FC<ReserveFormProps> = ({
    course,
    creditCards,
    onConfirm,
    user,
    values,
    venue,
}) => {
    const [step, setStep] = useState(1);

    useScrollToTop(step);

    const [input, setInput] = useState<ReservationCreateInput>(() => ({
        allergy: '',
        courseId: course.id || '',
        creditCardId: '',
        dateTime: values.time,
        numberOfPeople: Number(values.partySize),
        seatingType: values.seatingType,
        serviceType: course.serviceType,
        venueId: venue.id,
        visitFrequency: VisitFrequency.FirstVisit,
        visitPurpose: VisitPurpose.Other,
        waitingListTimeRange: values.time
            ? undefined
            : {
                  dateTimeRangeEnd: new Date(Number(values.waitlistArrivalEnd)),
                  dateTimeRangeStart: new Date(
                      Number(values.waitlistArrivalStart)
                  ),
                  deadline: values.waitlistDeadline,
              },
    }));

    const onSubmitDetails = (value: ReserveDetailsInput) => {
        setInput((prev) => ({...prev, ...value}));
        setStep((p) => p + 1);
    };

    const onSubmitPayment = (value: ReservePaymentInput) => {
        setInput((prev) => ({...prev, ...value}));
        setStep((p) => p + 1);
    };

    const onSubmitSummary = (id: string) => {
        onConfirm(id);
    };

    const onBack = () => setStep((p) => Math.max(1, p - 1));

    return (
        <Stripe>
            <ReserveSteps
                isRealTimeBooking={venue.realTimeBooking}
                onBack={onBack}
                step={step}
            />
            {step === 1 ? (
                <ReserveDetails
                    input={input}
                    onSubmit={onSubmitDetails}
                    user={user}
                    venue={venue}
                />
            ) : step === 2 ? (
                <ReservePayment
                    creditCards={creditCards}
                    input={input}
                    onBack={onBack}
                    onSubmit={onSubmitPayment}
                    venue={venue}
                />
            ) : (
                <ReserveSummary
                    course={course}
                    creditCards={creditCards}
                    input={input}
                    onBack={onBack}
                    onSubmit={onSubmitSummary}
                    user={user}
                    venue={venue}
                />
            )}
        </Stripe>
    );
};

export default ReserveForm;
